<template>
  <div>
    <v-card
      flat
      v-if="notifications.length > 0"
    > 
      <v-list
        flat
        class="pt-0"
        three-line
      >
        <v-list-item-group
          v-model="activeItems"
          aria-label="notification items"
          active-class="active--item"
        >
          <template v-for="(item, index) in notifications">
            <div
              class="d-flex flex-row"
              style="width:100%"
              :key="'swiper'+index"
              v-if="item.title && item.deepLinkType"
            >
              <div
                v-touch="{left:() => onSwipeLeft(index),right:() => onSwipeRight(index) }"
                style="position:relative;"
                class="swipe--item"
                :key="'swipe_'+index"
              >
                <v-list-item
                  style="width:100%"
                  class="pa-4 py-0"
                  role="row"
                  :aria-label="'notification item ' + (index + 1)"
                  :style="index < notifications.length - 1 ? 'border-bottom:1px solid #A0A5AE':''"                  
                >
                  <template v-slot:default="{ }">
                    <v-list-item-action
                      :id="'swipe_'+item.id"
                      aria-hidden="true"
                      role="option"
                      class="icon_logo mr-4 mt-4"
                    >
                      <div :class="selectedItems.filter((selectedRow) => selectedRow.id == item.id).length == 0 ? '':'selected-icon'">
                        <span aria-label="double click to see options" @keyup.enter="onSwipeLeft(index)" @click="onSwipeLeft(index)" v-if="selectedItems.filter((selectedRow) => selectedRow.id == item.id).length == 0">
                          <AppIcon :app-name="getAppName(item.fromAppName ? item.fromAppName.toLowerCase():'')" />
                        </span>
                        <v-icon
                          v-if="selectedItems.filter((selectedRow) => selectedRow.id == item.id).length > 0"
                          color="#041E42"
                          style="padding:6px;"
                          size="24"
                        >
                          check_box
                        </v-icon>
                      </div>
                    </v-list-item-action>
                    <v-list-item-content
                      tabindex="0"
                      role="option"
                      :aria-label="item.title +' '+item.content + ' '+ (item.time !='' ? timeSince(item.time)+' ago' : '') + 'double tap to goto issue'"
                      @click="item.link ? openLink(item.link,item.deepLinkType): ''"
                      @keyup.enter="item.link ? openLink(item.link,item.deepLinkType): ''"
                    >
                      <v-list-item-title
                        class="notification-title pb-2 pt-1 d-flex"
                        style="justify-content: space-between;"
                        aria-hidden="true"
                      >
                        <span :aria-label="item.title">{{ item.title }}</span>
                        <span v-if="item.read"><v-icon
                          size="8"
                          style="float:right"
                          color="#407EC9"
                          class="pl-3"
                        >circle</v-icon></span>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="notification-title notification--context notification--content pb-4"
                        v-text="item.content"
                        aria-hidden="true"
                      />
                      <v-list-item-subtitle
                        class="notification--content notification--subtitle"
                        v-text="item.time !='' ? timeSince(item.time)+' ago' : ''"
                        aria-hidden="true"
                      />
                    </v-list-item-content>
                    <v-list-item-action
                      role="option" 
                      tabindex="0"
                      style="display:none;"
                      :id="'swipe_element'+item.id"
                      class="align-self-stretch px-4 mt-0 mb-0 swipe"
                      @click="()=> deleteItem(index)"
                      @keyup.enter="()=> deleteItem(index)"
                    >
                      <div
                        class="d-flex justify-center"
                        style="height:100%;"
                      >
                        <div
                          class="swipe--behind align-self-center"
                          style="align-self:center"
                        >
                          <div style="text-align: center;">
                            <v-icon
                              size="30"
                            >
                              delete
                            </v-icon>
                            <div>Remove</div>
                          </div>
                        </div>
                      </div>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </div>
            </div>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-card
      flat
      v-if="notifications.length == 0 && !isLoading"
      style="text-align:center"
      class="mt-10 flex"
    >
      <div
        role=""
        class="pt-10"
      >
        <span
          class="pt-4
         pb-7 flex-start"
        >
          <img
            alt="no notification image"
            src="../../public/assets/no_notification.svg"
          >
        </span>
      </div>
      <span
        role="heading"
        class="noItem"
      >No notifications</span>
    </v-card>
  </div>
</template>
<script>
import utils from '../settings/utils';
import { mapGetters } from 'vuex';
import lodash from 'lodash';
import { AppIcon } from '@nswdoe/doe-ui-core';
import LongPress from 'vue-directive-long-press';
  export default {
    name: "Notifications",
    components:{
      AppIcon
    },
    props: {
      notificationData: {
        type: Array,
        default: function () { return [] }
      },
      clearSelection: {
        type: Boolean,
        default: false
      },
      isLoading:{
        type: Boolean,
        default: false
      }
    },
    computed: {      
      ...mapGetters({ tokens:'tokens' }),
      notifications(){
        let notificationData = lodash.orderBy(this.notificationData,function(item){
          return item.receivedDatetime  },'desc');
        let notificationsDataObj = notificationData.map( (item) => { 
          return {
            id: item.id,
            title: item.messageTitle ? item.messageTitle : item.messageContent && item.messageContent.title ? item.messageContent.title: '',
            content: item.messageContent && item.messageContent.body ? item.messageContent.body : item.messageBody ? item.messageBody : "",
            link:item.messageDeeplink ? item.messageDeeplink : item.messageContent && item.messageContent.data && item.messageContent.data.deeplink
                ? item.messageContent.data.deeplink
                : null,
            time: item.receivedDatetime ? item.receivedDatetime : '',
            deepLinkType: item.messageDeeplinkType ? item.messageDeeplinkType : null,
            fromAppName: item.fromAppName, 
            read: item.readDatetime && item.readDatetime != "" ? true : false
            }
            }
            );
        return notificationsDataObj.filter(notification => notification.title && notification.deepLinkType)
      }
    },
    directives:{
      'long-press': LongPress
    },
    methods:{
      openLink(link,type){
        if(type && type.length > 0 && type.toLowerCase()=="essentials"){
        this.$router.push('/essentials?deeplink='+ link);
        }else if(type && type.length > 0 && (type.toLowerCase().indexOf('link') != -1 || type.toLowerCase() == "link")){
        //eslint-disable-next-line
        NativeJS.openWindow(link,1);
        }
      },
      getAppName(name){
        switch (name){
        case "cica": return "my-ci-checklist"
        case "mypl": return "my-my-pl-edu"
        case "schoolbiz": return "my-school-biz"
        default: return "my-generic-ideas"
        }},
      selectItem() {
        /* API not supporting
        if(this.selectedItems.length > 0){
        let itemExist = -1;
        this.selectedItems.forEach((selectedItem, index) => { 
          if(selectedItem.id == item.id){
            itemExist = index;
          }
        });
        
        if(itemExist == -1){
          this.selectedItems.push(item);
        }else{
          this.selectedItems.splice(itemExist,1);
        }

        }else{
          this.selectedItems.push(item);
        }

        if(localStorage){
          localStorage.setItem("itemSelected",JSON.stringify(this.selectedItems));
        }

        this.$emit('itemSelected'); */
      },
      onSwipeLeft(index){
        this.clearSelected();
        let item = this.notifications[index];
        if(document.getElementsByClassName("swipeLeft") && document.getElementsByClassName("swipeLeft").length > 0){
        document.getElementsByClassName("swipeLeft")[0].classList.remove("swipeLeft");
        }
        if(document.getElementsByClassName("swipeLeftElement") && document.getElementsByClassName("swipeLeftElement").length > 0){
        document.getElementsByClassName("swipeLeftElement")[0].classList.remove("swipeLeftElement");
        }
        document.getElementById('swipe_'+item.id).classList.add("swipeLeft");
        document.getElementById('swipe_element'+item.id).classList.add("swipeLeftElement");

        this.selectedItems = [];
        if(localStorage){
          let loggedInUser = this.tokens ? this.tokens.user_name : '';
          utils.setLocalStorage(loggedInUser,'itemSelected',JSON.stringify(this.selectedItems));
        }
        this.selectedItems.push(item);
        if(localStorage){
          let loggedInUser = this.tokens ? this.tokens.user_name : '';
          utils.setLocalStorage(loggedInUser,'itemSelected',JSON.stringify(this.selectedItems));
        }
        
      },
      deleteItem(index){
        let item = this.notifications[index];
        document.getElementById('swipe_'+item.id).classList.remove("swipeLeft");
        document.getElementById('swipe_'+item.id).classList.remove("swipeLeftElement");
        
        this.$emit('checkMessage');
      },
      onSwipeRight(index){
        let item = this.notifications[index];
        this.selectedItems = [];

        if(localStorage){
          let loggedInUser = this.tokens ? this.tokens.user_name : '';
          utils.setLocalStorage(loggedInUser,'itemSelected',JSON.stringify(this.selectedItems));
        }

        document.getElementById('swipe_'+item.id).classList.remove("swipeLeft");
        document.getElementById('swipe_element'+item.id).classList.remove("swipeLeftElement");
        this.$emit('itemSelected');

      },

      clearSelected(){

        if(localStorage){
          this.selectedItems = [];
          let loggedInUser = this.tokens ? this.tokens.user_name : '';
          utils.setLocalStorage(loggedInUser,'itemSelected',JSON.stringify(this.selectedItems));
          this.activeItems = [];
          this.$emit('itemSelected');

          if(document.getElementsByClassName("swipeLeft") && document.getElementsByClassName("swipeLeft").length > 0){
            document.getElementsByClassName("swipeLeft")[0].classList.remove("swipeLeft");
          }
          if(document.getElementsByClassName("swipeLeftElement") && document.getElementsByClassName("swipeLeftElement").length > 0){
            document.getElementsByClassName("swipeLeftElement")[0].classList.remove("swipeLeftElement");
          }
          
        }
      },
      timeSince(timestamp) {
      
      let date = new Date(timestamp);

      let seconds = Math.floor((new Date() - date) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return interval + " years";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + " months";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        return interval + " month";
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + " days";
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + " hours";
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
    getIssue(articlePath){
      if(articlePath){

        let splitPath = articlePath.split('/');
        let length = splitPath.length;

        if(length > 1){

          return splitPath[length - 1];
        }else if(length > 0){

          return splitPath[0];
        }else{

          return splitPath;
        }
      }else{

        return 'noIssue';
      }
    }
    },
    data() {
      return {
      selectedItems: Array(0),
      activeItems: []
      }
    },
    watch:{
      clearSelection: function(){
          this.selectedItems = [];
          this.clearSelected();
      }
    }
  }
</script>
<style lang="scss" scoped>
  .v-icon{
    color:#ffffff;
  }
  .swipe{
    background:#B81237;
  }
  .noItem{
    color:#6D7079;
  }
  .notification-title {
  font-family: Arial;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-overflow: unset;
  word-break: break-word;
  white-space: normal;
  color:#121212;
  }
  ::v-deep .swipeLeft{
      animation: 0.1s linear 0s slide;
      margin-left: -80px;
  }
  
  @keyframes slide {
  from { left: 0; }
  to {
    margin-left: -80px;
    transform: translateX(1px);
  }
  }

  ::v-deep .swipeLeftElement{
      display: block !important;
      right: 0;
      margin-right:-16px;
  }

  .swipe--item{
    background:#ffffff;
    z-index: 9;
    width:100%;
  }
  ::v-deep .swipe--behind{
    color:#fff;
    font-size:12px;
  }
  .active--item{ color: #ffffff; } .selected-icon{ background-color: #ffffff; }
  .notification--content {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-overflow: unset;
  word-break: break-word;
  -webkit-line-clamp: 10 !important;
  white-space: normal;
  }
  
  .icon_logo {
    border-radius: 7px;
  }

  .appTitle {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #121212;
  }

  .subheading{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #121212;
  }

  .no-result{
    word-wrap: break-word;
  }

  .col{
    flex-grow: 0;
    flex-basis:33%;
  }
  .notification--subtitle{
    color: #6D7079;
  }
  .v-application a{
    text-decoration: none;
  }

</style>