<template>
  <div>
    <appBarMobile
      color="primary"
      :show-app-icon="false"
      :show-search-filter="false"
      :title="title"
      :show-nav-icon="false"
      :show-back-button="showBackButton"
      :show-custom-icon="false"
      :reset-selection="clearSelected"
    >
      <template slot="customIcon">
        <v-icon
          v-if="unreadSelected"
          @click="markUnread()"
          tooltip="mark read"
          class="mr-4 material-icons-outlined"
        >
          email
        </v-icon>
        <v-icon
          v-if="readSelected"
          @click="markRead()"
          tooltip="mark unread"
          class="mr-4 material-icons-outlined"
        >
          mdi-email-open-outline
        </v-icon>
        <v-icon
          v-if="selected && selected.length > 0"
          @click="deleteMessage()"
          class="pr-1 material-icons-outlined"
        >
          delete
        </v-icon>
      </template>
    </appBarMobile>
    <Notifications
      class="ma-n4 notification"
      :is-loading="isLoading"
      @checkMessage="checkMessage"
      @itemSelected="itemSelected"
      :clear-selection="clearSelection"
      :notification-data="notificationData"
    />
    <v-snackbar
      v-model="snackbar"
      class="mb-10 pb-10"
    >
      {{ text }}

      <v-btn
        color="#6BACE4"
        icon
        text
        @click="snackbar=false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Notifications from "@/components/Notifications";
import appBarMobile from "@/components/appBarMobile";
import utils from '@/settings/utils';
export default {
  components: {
    Notifications,
    appBarMobile
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      notificationsInfo: "notifications",
      notificationStatus: 'notificationStatus',
      tokens: "tokens"
    }),
    notificationData(){
      return this.notificationsInfo.filter(notificationObj => !notificationObj.displayMethod ||
              (notificationObj.displayMethod &&
                notificationObj.displayMethod.toLowerCase() != "portal_banner"))
    },
    title(){
          let profileObj = this.$store.state.directoryModule.loginProfile;
          return 'Welcome ' +( profileObj != null && profileObj.first_name != undefined && profileObj.first_name != 'undefined' ? profileObj.first_name : '');
    }
  },
  created() {
    this.$store.dispatch("fetchMyNotifications");
    window.scrollTo(0,0);
  },
  data: function() {
    return {
      readSelected:false,
      unreadSelected: false,
      clearSelection: false,
      snackbar:false,
      text:'',
      showBackButton: false,
      selected: []
    };
  },
  methods: {
    itemSelected(){
      if(localStorage && utils.getLocalStorage(this.tokens.user_name,"itemSelected")){
      this.selected = utils.getLocalStorage(this.tokens.user_name,"itemSelected");
      
      this.readSelected = this.selected.find( (item) => item.read == true) ? true : false;
      this.unreadSelected = this.selected.find( (item) => { return item.read == false}) ? true : false;
      if(this.selected.length > 0){
        this.showBackButton = true;
        //this.title = this.selected.length + " Selected";
      }else{
        this.showBackButton = false;
        //this.title= "Notifications";
      }
      }else{
        this.selected = [];
        this.readSelected = false;
        this.unreadSelected = false;
        this.showBackButton = false;
        //this.title= "Notifications";
      }
     this.clearSelection = false;
    },
    checkMessage(){
      this.itemSelected();
      this.deleteMessage();
    },
    clearSelected(){
    this.clearSelection=true;
    },
    markRead(){
      
      let notificationData = this.notificationData;
      let updatedData = notificationData.map((notificationItem) => {
            this.selected.forEach((item) => {
                
                if(notificationItem.id == item.id){
                  notificationItem.readDatetime = null;
                }

           });
           return notificationItem;

      });
      this.$store.dispatch("updateNotifications",updatedData);
      this.clearSelection = true;
      
    },
    markUnread(){
      
      let notificationData = this.notificationData;
      let updatedData = notificationData.map((notificationItem) => {
            this.selected.forEach((item) => {
                
                if(notificationItem.id == item.id){
                  notificationItem.readDatetime = "some time";
                }

           });
           return notificationItem;

      });
      this.$store.dispatch("updateNotifications",updatedData);
      this.clearSelection = true;

    },
    deleteMessage(){
      let notificationData = this.notificationData;
      this.clearSelection = true;
      let updatedData = [];
      notificationData.forEach((notificationItem) => {
            let deletedItem = this.selected.find((item) => 
                notificationItem.id == item.id
            );
            if(deletedItem)
             updatedData.push(notificationItem);
      });
      this.$store.dispatch("updateNotifications",updatedData).then(()=>{
        if(this.notificationStatus && this.notificationStatus.status){
          this.text = this.notificationStatus.text;
          this.snackbar = true;
        }
      })
      .finally(()=>{
        this.$store.dispatch("updateNotificationStatus",updatedData);
      });
    }
  }
};
</script>

<style scoped>
</style>